@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.form {
  background-color: var(--contextual-colors-brand-primary-inactive);
  border-radius: 4px;
  width: 100%;
}

.spinnerWrapper {
  margin: spacing(1.5) auto spacing(1);
  text-align: center;
}

.spinner {
  display: inline-block;
}

.loadingMessage {
  font-size: rem(12.26);
  margin: spacing(1) auto spacing(1.5);
  text-align: center;
}

.pinsNode {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.formError {
  color: var(--error-main);
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  margin: 0 spacing(1.5) spacing(2);
  width: auto;

  @include devices(sm-up) {
    justify-content: center;
  }
}
