@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  flex: none;

  @include devices(md-up) {
    background-color: var(--common-white);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 8px;
    border: 1px solid var(--contextual-colors-neutral);
    width: 270px;
    height: 100%;
  }
}

.content {
  padding-top: spacing(1);
  position: relative;

  @include devices(md-up) {
    padding: spacing(1);
    display: flex;
  }
}

.minimalContent {
  @include devices(md-up) {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;

    .avatar {
      margin-right: 0;
    }

    .onlineStatus {
      right: spacing(1);
    }
  }
}

.expertData {
  flex: 1;
  line-height: 1.35;
  display: flex;
  flex-direction: column;

  @include devices(md-up) {
    max-width: none;
  }
}

.expertMinimalData {
  overflow: hidden;
  max-width: 80px;

  @include devices(md-up) {
    max-width: none;
  }
}

.avatar {
  width: 80px;
  height: 100px;

  @include devices(md-up) {
    margin-right: spacing(1);
  }

  .onlineStatus {
    min-width: 11px;
    min-height: 11px;
    border-width: 1px;

    @include devices(md-up) {
      min-width: 15px;
      min-height: 15px;
    }
  }
}

.footer {
  padding: spacing(1);
  margin-top: spacing(1);
  border-top: 1px solid var(--contextual-colors-neutral);
}

.favoriteButton {
  align-self: flex-end;
}

.expertName {
  color: var(--primary-main);
  font-weight: var(--font-weight-medium);
  font-size: rem(13);
  margin-top: spacing(1);
  display: -webkit-box;
  line-clamp: 2;
  box-orient: vertical;
  overflow: hidden;
  text-align: center;
  word-break: break-word;

  @include devices(md-up) {
    color: var(--grey-700);
    font-size: rem(17);
    margin-bottom: spacing(0.75);
    text-overflow: ellipsis;
    overflow-x: hidden;
    text-align: left;
    max-height: 60px;
  }
}

.promotion {
  font-size: rem(14);
}

.contactDate {
  font-size: rem(12);
}

.productSection {
  display: flex;
  margin-bottom: spacing(0.5);
  align-items: flex-start;
}
