@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  padding: 20px 0px 0px 16px;
  background-color: var(--common-white);
  border-radius: 8px 8px 0px 0px;
  box-shadow: 0px -10px 10px rgba(147, 147, 147, 0.15);
}

.label {
  font-weight: var(--font-weight-bold);
  font-size: rem(19);
  line-height: 23px;
}

.list {
  width: calc(100vw - 16px);
  height: 165px;
  padding-top: spacing(1);
  max-width: 100%;

  margin-right: 0;

  & > div + div {
    margin-left: 0;
  }
}

.expert {
  margin-right: 8px;
  width: 80px;

  @include devices(md-up) {
    margin-right: 0;
  }
}

.avatar {
  width: inherit;
  height: 100px;
}

.name {
  text-align: center;
  font-weight: var(--font-weight-medium);
  font-size: rem(14);
  line-height: 19px;
  color: var(--contextual-colors-brand-primary);
  margin-top: 8px;
}

.toggler {
  width: 48px;
  height: 48px;
  min-width: auto;
  border: 3px solid var(--common-white);
  position: absolute;
  top: -24px;
  right: 16px;
}

.status {
  min-width: 15px;
  min-height: 15px;
}

.line {
  height: 1px;
  background: var(--grey--a200);
  margin: spacing(1, 2, 0, 0);
}
