@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.root {
  width: 100%;
  padding: spacing(0, 1.5);
  padding-bottom: spacing(2);
}

.dateField {
  width: 100%;
  margin-bottom: spacing(0.5);
}

.button {
  height: 36px;
  width: 184px;
  font-weight: var(--font-weight-bold);
  font-size: rem(14);
  margin-top: spacing(1);
}

.info {
  font-size: rem(12);
  font-weight: var(--font-weight-regular);
  line-height: 14px;
  color: var(--contextual-colors-ui-secondary);
  margin-left: spacing(2);
}

.infoActive {
  color: var(--contextual-colors-brand-primary);
}

.topSection {
  z-index: 10;
  position: relative;

  @include devices(sm-up) {
    max-width: 416px;
  }
}
