@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.promotionItem {
  position: relative;
  cursor: pointer;
}

.disabledPromotion {
  opacity: 0.5;
  pointer-events: none;
}

.slider {
  position: relative;
  overflow: hidden;
  margin-bottom: spacing(3);

  &:not(:global(.slick-initialized)) {
    visibility: hidden;
  }

  & :global(.slick-track) {
    display: flex;
    margin-left: initial;
    margin-right: initial;
  }

  & :global(.slick-slide) {
    padding-right: spacing(2);
    display: flex;
    height: auto;
  }
}

.disableSliderScroll {
  pointer-events: none;
}

.button {
  height: 30px;
  width: 30px;
  padding: 0;
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
  z-index: 10;
  background-color: var(--common-white);
  border: 1px solid;
  border-radius: 50%;
  color: var(--primary-main);
  line-height: 0;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:before {
    content: none;
  }

  &:hover {
    background-color: var(--primary-dark);
    color: var(--common-white);
  }

  &:global(.slick-disabled) {
    display: none;
  }

  @include devices(md-down) {
    left: 0;
  }
}

.nextButton {
  transform: rotate(180deg);
  left: auto;
  right: 0;
}

.promotionCoupon {
  position: relative;
}

.expansionPanel.expansionPanel {
  background: none;
  margin: 0;

  :global(.MuiAccordionSummary-expandIconWrapper) {
    color: var(--contextual-colors-brand-primary);
  }
}

.panelTitleNeutral {
  color: var(--contextual-colors-ui-secondary);
}

.panelTitleAttention {
  color: var(--contextual-colors-brand-secondary);
}

.expansionPanelDetails {
  padding-top: 0;
}
