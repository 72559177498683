@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  padding-top: spacing(2);
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: spacing(4, 0);
  background-color: var(--common-white);
}

.title {
  color: var(--error-main);
}

.description {
  text-align: center;
  font-size: rem(14);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.actionButton {
  min-width: 200px;

  @include devices(sm-up) {
    min-width: 300px;
  }
}

.imageWrapper {
  position: relative;
  width: 150px;
  height: 150px;

  @include devices(sm-up) {
    width: 200px;
    height: 200px;
  }
}
