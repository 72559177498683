@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.skillsBlock {
  display: flex;
  flex-direction: column;
  background: var(--grey-100);
  border-radius: 4px;
  padding: spacing(3);
  text-align: center;
  height: 100%;
  width: 100%;

  @include devices(md-up) {
    margin: auto;
    padding: spacing(4);
  }
}

.blockTitle {
  font-size: rem(19.2);
  margin-bottom: spacing(2);
}

.skillsList {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;
  padding-left: 0;
  margin: 0 auto;
  text-align: left;
}

.skillsListItem {
  display: flex;
  gap: 5px;
  align-items: center;
  color: var(--text-secondary);
  line-height: 24px;

  @include devices(md-up) {
    font-size: rem(16);
  }
}
