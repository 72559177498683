@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.list {
  position: relative;
  display: flex;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include devices(sm-down) {
    margin-right: spacing(-2);
  }

  &::-webkit-scrollbar {
    display: none;
  }

  & > div + div {
    margin-left: spacing(2);
  }
}

.slider {
  position: relative;
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 40px;
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }

  &:not(:global(.slick-initialized)) {
    visibility: hidden;
  }

  :global(.slick-track) {
    display: flex;
  }

  :global(.slick-slide) {
    padding-right: spacing(2);
    display: flex;
    height: auto;
  }

  &.minimalExpertView {
    :global(.slick-slide) {
      padding-right: spacing(1);
    }
  }
}

.button {
  height: 30px;
  width: 30px;
  padding: 0;
  position: absolute;
  top: calc(50% - 15px);
  left: 0;
  z-index: 10;
  background-color: var(--common-white);
  border: 1px solid;
  border-radius: 50%;
  color: var(--primary-main);
  line-height: 0;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--primary-dark);
    color: var(--common-white);
  }

  @include devices(md-down) {
    left: 0;
  }
}

.nextButton {
  transform: rotate(180deg);
  left: auto;
  right: 0;
}
