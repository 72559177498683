@use "@styles/partials/functions" as *;

.root {
  position: fixed;
  bottom: 100px;
  right: 16px;
  z-index: 10;
}

.label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 168px;
  height: 32px;
  color: var(--contextual-colors-brand-primary);
  font-size: rem(14);
  line-height: 24px;
  background-color: var(--common-white);
  border-radius: 50px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  font-weight: var(--font-weight-bold);
}

.list {
  display: flex;
}

.avatarWrapper {
  position: relative;

  &:not(:first-child) {
    margin-left: spacing(-1);
  }
}

.avatar {
  width: 48px;
  height: 48px;
  border: 3px solid var(--common-white);
}

.toggler {
  margin-left: spacing(-1);
  min-width: auto;
}

.indicator {
  transform: translate(-25%, -25%);
  min-width: 12px;
  min-height: 12px;
}
