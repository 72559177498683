@use "@styles/partials/functions" as *;

.root {
  display: flex;
  align-items: center;
}

.arrow {
  line-height: 0;
  transform: rotate(180deg);
  margin-right: spacing(1);
  color: var(--grey-300);
}

.text {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
}
