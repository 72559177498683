@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: spacing(2);
  border-bottom: 1px solid var(--contextual-colors-neutral);
  padding-top: spacing(1.5);
  padding-bottom: spacing(1.5);

  &:last-child {
    border-bottom: none;
  }

  @include devices(md-up) {
    padding: spacing(2.25, 7.5, 2.25, 7.5);
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: spacing(1.75);
  flex-grow: 1;
}

.title {
  font-size: rem(14);
  color: var(--text-secondary);
}

.fieldWrapper {
  color: var(--text-primary);
  display: flex;
  flex-direction: row;
  font-size: rem(14);

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.errorWrapper {
  display: flex;
  align-items: center;
}

.error {
  color: var(--secondary-main);
  font-weight: var(--font-weight-medium);
}

.errorMessage {
  margin-left: spacing(0.5);
}

.iconWrapper {
  margin-left: spacing(1);
}

.iconButton {
  border-radius: 4px;
}

.iconButtonLabel {
  display: none;
  font-size: rem(16);
  font-weight: var(--font-weight-medium);

  @include devices(md-up) {
    display: block;
  }
}
