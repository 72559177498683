@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.root {
  display: flex;
  align-items: flex-start;
  color: var(--contextual-colors-ui-secondary);
  margin-top: spacing(0.5);
}

.consentRoot {
  align-items: flex-start;
  margin-right: 0;
  margin-left: 0;
}

.consentLabel {
  font-size: rem(12);
  @include devices(sm-up) {
    margin-right: spacing(0.5);
    font-size: rem(14);
    text-align: left;
  }
}

.link {
  text-decoration: underline;
}

.checkbox {
  width: 24px;
  height: 24px;
  margin-right: spacing(0.5);
}

.loaderClassName {
  padding: spacing(1) 0 0 spacing(2);
  background-color: transparent;
  justify-content: flex-start;
}
