@use "@styles/partials/functions" as *;

.mainHeadline {
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  font-size: rem(18);
  line-height: 23px;
  gap: 10px;
}

.numberHeadline {
  font-weight: var(--font-weight-bold);
  display: flex;
  align-items: center;
  font-size: rem(14);
  line-height: 21px;
  gap: 8px;
  margin-bottom: spacing(0.75);
}

.info {
  margin-bottom: spacing(1.5);
  line-height: 21px;
}

.bold {
  font-weight: var(--font-weight-bold);
}

.details {
  background: var(--background-default);
  padding: spacing(0, 2, 2);
}

.panel {
  margin-top: spacing(2);
  margin-bottom: spacing(3);

  & > :global(.MuiExpansionPanelSummary-root) {
    padding: spacing(2, 2, 1.5);
    min-height: auto !important;
    height: 51px;
  }

  & :global(.MuiExpansionPanelSummary-content) {
    margin: 0 !important;
  }
}
