@use "@styles/partials/functions" as *;

.resendText {
  font-size: rem(12.26);
  margin-top: spacing(1.5);
  text-align: center;
}

.time {
  color: var(--primary-main);
}

.resendButton {
  color: var(--primary-main);
  cursor: pointer;
}
