@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.subtitle {
  margin-bottom: spacing(3);
  line-height: 28px;
  font-size: 1rem;
  color: var(--text-secondary);

  @include devices(md-up) {
    color: var(--text-primary);
    font-size: 1.2rem;
    text-align: center;
  }
}

.section {
  padding-left: spacing(2);

  @include devices(md-up) {
    height: 100%;
    flex: 1;
    background-color: var(--background-default);
    padding: spacing(4, 3.125, 4, 5);
    border-radius: 4px;

    &:not(:last-child) {
      margin: spacing(0, 0, 2, 0);
    }
  }

  @include devices(md-up) {
    &:not(:last-child) {
      margin-right: spacing(2);
    }
  }
}

.content {
  display: flex;
  flex-direction: row;
}

.itemsList {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
}

.item {
  position: relative;
  display: flex;
  padding-left: spacing(1);

  &:not(:last-child) {
    padding-bottom: spacing(2);

    @include devices(md-up) {
      padding-bottom: spacing(3);
    }
  }

  @include devices(md-up) {
    padding-left: spacing(0.5);

    .year,
    .desc {
      font-size: rem(16);
    }
  }

  &::before {
    display: inline-block;
    left: -13px;
    top: 5px;
    content: "";
    height: 16px;
    width: 16px;
    background-color: var(--primary-main);
    border-radius: 50%;
    position: absolute;
  }
}

.year {
  margin-left: spacing(0.625);
  margin-right: spacing(1.5);
  line-height: 28px;

  @include devices(md-up) {
    margin-left: spacing(0.5);
    margin-right: spacing(1.75);
  }
}

.desc {
  line-height: 28px;
}

.sectionTitle {
  font-size: rem(24);
  margin-bottom: spacing(2);

  @include devices(md-up) {
    font-size: rem(33);
    text-align: center;
    margin-bottom: spacing(7.5);
  }
}

.leftBorder {
  position: absolute;
  border-left: 5px dotted #e2e1e1;
  height: calc(100% - 25px);
  top: 25px;
  left: -7px;
}
