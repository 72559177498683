@keyframes animation {
  0% {
    height: 0;
    width: 0;
    opacity: 0.25;
  }
  20% {
    height: 0;
    width: 9px;
    opacity: 0.5;
  }
  40% {
    height: 18px;
    width: 9px;
    opacity: 0.75;
  }
  100% {
    height: 18px;
    width: 9px;
    opacity: 1;
  }
}

.checkmarkCircle {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  border: 1px solid white;
  overflow: hidden;

  &:after {
    content: "";
    opacity: 0;
    height: 0;
    width: 0;
    transform-origin: left top;
    border-right: 1px solid white;
    border-top: 1px solid white;
    border-radius: 2px;
    left: 4px;
    top: 12px;
    position: absolute;
    animation-delay: 100ms;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-name: animation;
    transform: scaleX(-1) rotate(135deg);
    animation-fill-mode: forwards;
  }
}
