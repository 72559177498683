@use "@styles/partials/functions" as *;

.field {
  height: 42px;
  width: 42px;
  margin: spacing(1.5, 1);
}

.fieldOutline {
  border-width: 2px;
}

.successFieldOutline {
  border-color: var(--success-main);
}

.inputRoot {
  border-radius: 8px;
}

.inputElement {
  font-size: rem(14);
  font-weight: var(--font-weight-bold);
  padding: 12px;
  text-align: center;

  &[type="number"] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
