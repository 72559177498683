@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.actions {
  display: flex;
  justify-content: space-between;
  max-width: 100px;
  margin: spacing(-2) auto 0;

  @include devices(sm-up) {
    max-width: 116px;
    margin-top: spacing(-2.5);
  }
}
