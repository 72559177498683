@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.button {
  background-color: var(--contextual-colors-bg-primary-accent);
  color: var(--primary-main);
  cursor: pointer;
  transition: background-color 0.25s;
  box-shadow: none;
  padding: 6px;

  @include devices(s-down) {
    width: 34px;
    height: 34px;
  }

  &:hover,
  &:focus {
    background-color: var(--contextual-colors-bg-secondary-accent);
    color: var(--primary-main);
  }

  &:disabled {
    color: var(--contextual-colors-negative);
    background-color: var(--contextual-colors-button-disabled);
    cursor: default;
  }

  &:focus {
    outline: none;
  }
}
