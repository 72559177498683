@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.paperRoot {
  margin-top: spacing(2);
  overflow-x: hidden;

  @include devices(md-up) {
    margin-top: spacing(3);
  }
}

.pinFormRoot {
  margin-top: spacing(2);

  @include devices(md-up) {
    margin-top: spacing(3);
  }
}
