@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  text-align: center;
}

.description {
  color: var(--text-primary);
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
}

.bottomDescription {
  margin: spacing(2, 0);
  @include devices(md-up) {
    margin: spacing(3, 0);
  }
}

.button {
  width: 100%;
  max-width: 300px;
}
