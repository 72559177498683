@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

$profile-hero-width: 248px;
$profile-hero-height: 293px;
$products-width: 246px;
$navbar-height: 64px;
$section-margin: 60px;

.root {
  padding-top: spacing(1);
  position: relative;

  @include devices(md-up) {
    padding-top: spacing(2);
  }
}

.column {
  @include devices(md-up) {
    margin-bottom: spacing(2);
    margin-left: spacing(3);
    margin-right: spacing(3);
    margin-top: $section-margin;
    position: absolute;
    bottom: 0;
    top: spacing(2);
  }
}

.sidebarColumn {
  display: none;

  @include devices(md-up) {
    display: block;
    left: 0;
    width: $profile-hero-width;
  }
}

.productsColumn {
  @include devices(md-up) {
    right: 0;
    width: $products-width;
  }
}

.section.section {
  margin-bottom: spacing(1);
  padding: spacing(2);
  scroll-margin-top: $navbar-height;

  @include devices(md-up) {
    padding-bottom: $section-margin;
    padding-left: calc($profile-hero-width + spacing(6));
    padding-right: calc($products-width + spacing(6));
    padding-top: $section-margin;
  }
}

.sectionLeftAlign {
  @include devices(md-up) {
    margin: 0;
  }
}

.profileInfo {
  margin-bottom: spacing(3);
  display: flex;

  @include devices(s-down) {
    margin-bottom: 0;
  }
}

.personalNote {
  margin-bottom: spacing(2);
}

.sectionTitle {
  font-size: rem(19);
  margin-bottom: spacing(2);
}

.descriptionTeaser {
  font-size: rem(14);
  line-height: 1.42;
  margin-bottom: spacing(1.5);
  font-weight: var(--font-weight-medium);

  @include devices(md-up) {
    font-size: rem(16);
    margin-bottom: spacing(3);
  }
}

.expertProductsAndDescWrapper {
  background: var(--background-default);
  border-radius: 4px;
  padding: spacing(2);
}

.title {
  display: flex;
  justify-content: left;
  margin-bottom: spacing(3);
  font-size: rem(24);
  line-height: 1.57;

  @include devices(md-up) {
    justify-content: center;
    font-size: rem(33.2);
    font-weight: var(--font-weight-bold);
    margin-bottom: spacing(7.5);
  }
}

.sectionColumn {
  display: flex;
  flex-direction: column;
}

.promotion {
  margin-bottom: spacing(1);
}

.description {
  color: var(--text-secondary);
  font-size: rem(14);
  line-height: 1.42;
  margin: 0;

  & *:not(img, figure) {
    width: 100% !important; // Override inline styles
  }

  & * {
    max-width: 100%;
  }

  & figure {
    margin: 0;
  }

  @include devices(md-up) {
    font-size: rem(16);
  }
}

.stickableProducts {
  padding: 0;

  @include devices(md-up) {
    position: sticky;
    top: var(--top-offset);
  }
}

.stickableProductsList {
  @include devices(md-up) {
    flex-direction: column;
  }
}

.productsWrapper {
  padding: spacing(1, 2, 2);
}
