@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.panel {
  background-color: var(--common-white);
  box-shadow: none;
}

.wrapper {
  &:not(:last-child) {
    border-bottom: 1px solid var(--contextual-colors-neutral);
    margin-bottom: spacing(2);
  }
}

.expanded {
  &.expanded {
    margin: 0;
  }
}

.group {
  padding: 0;
  position: relative;
  font-weight: var(--font-weight-bold);
  line-height: 18px;
  margin-left: spacing(1.5);

  @include devices(sm-up) {
    margin-left: spacing(0);
    font-size: rem(16);
    line-height: 21px;
    margin-bottom: spacing(1.5);
  }
}

.details {
  flex-direction: column;
  border-bottom: 1px solid var(--contextual-colors-neutral);
  padding: spacing(0, 0, 2);
}

.summary {
  padding: 0;
}

.content {
  margin: spacing(2, 0);
}
