@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.item {
  font-size: rem(14);
  line-height: 18px;
  margin-bottom: spacing(2);
  margin-left: spacing(3);

  @include devices(sm-up) {
    margin-left: 0;
    font-size: rem(16);
    line-height: 21px;
  }
}

.headline {
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(0.625);
}

.subheadline {
  font-weight: var(--font-weight-regular);
  padding-left: spacing(4);
}

.checkbox {
  width: 24px;
  height: 24px;
  margin-right: spacing(1);
}

.label {
  font-weight: var(--font-weight-medium);
  font-size: rem(14);

  @include devices(sm-up) {
    font-size: rem(16);
  }
}

.labelRoot {
  margin-left: 0;
}
