@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.suggestedExpertsWrapper {
  position: relative;
  width: 100%;
  padding: spacing(1.5, 2);
}
.minimizedExperts {
  position: static;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @include devices(md-up) {
    bottom: spacing(3);
  }
}

.products {
  padding: spacing(1.5, 2);
  border-top: 1px solid var(--contextual-colors-neutral);
}

.backLink {
  padding: spacing(2, 2, 0, 2);
  background-color: var(--common-white);

  @include devices(sm-up) {
    display: none;
  }
}
