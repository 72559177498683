@use "@styles/partials/breakpoints" as *;
@use "@styles/partials/functions" as *;

.wrapper {
  text-align: center;
}

.description {
  color: var(--text-primary);
  font-size: rem(16);
  font-weight: var(--font-weight-medium);
  margin-bottom: spacing(3);
}

.button {
  width: 100%;
  max-width: 300px;
}
