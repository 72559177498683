@use "@styles/partials/functions" as *;
@use "@styles/partials/breakpoints" as *;

.wrapper {
  display: flex;
  flex-direction: column;

  @include devices(md-up) {
    max-width: 1000px;
    flex-direction: row;
    margin-bottom: spacing(2.5);
  }
}

.avatarWrapper {
  position: relative;
  width: 80px;
  height: auto;
  margin-right: spacing(1);

  @include devices(s-up) {
    width: 127px;
    margin-right: spacing(1.5);
  }

  @include devices(sm-up) {
    width: 248px;
    margin-right: spacing(3);
  }
}
